/*
	BEGIN: 
	Calendar counter stuff.
*/
const easingFn = function (t, b, c, d) {
  var ts = (t /= d) * t;
  var tc = ts * t;
  return b + c * (tc + -3 * ts + 3 * t);
};

const easeInOutCubic = t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1;

const easeInOutSine = t => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2;

function initCounter() {
  const options = {
    startVal: 1, 
    duration: 2.5,
    easeInOutSine,
  };
  window.counter = new CountUp('stat', 7, options);
}

function startCounter() {
  if (!window.counter.error) {
    window.counter.start();
  } else {
    console.error(window.counter.error);
  }  
}

function resetCounter() {
  window.counter.reset();
}
/*
	END: 
	Calendar counter stuff.
*/

/*
	BEGIN:
	Observe stat widgets entering & leaving the viewport.
*/

function init_stat_observer() {
	if ((typeof IntersectionObserver) == 'undefined') {
		// For IE11 (and other unsupported browsers), skip the widget animations.
		$('.statWidget .circle, .statWidget .pill, .calwrap').addClass('endState');
	}
	const statWatch = (entries, observer) => {
		//entries.forEach(entry => {
		for (var entry of entries) {
			//console.log(entry);
			if (entry.isIntersecting === true && entry.intersectionRatio >= 1) {
				// This stat widget is on screen. Run the animation.
				entry.target.classList.add('activate');
				if (entry.target.id == 'statWidget-calendar') {
					setTimeout(startCounter, 2500); // wait 2.5 seconds for other animations to complete.
				}
			} else if (entry.isIntersecting === false) {
				// This stat widget is off screen. Reverse the animation.
				entry.target.classList.remove('activate');
				if (entry.target.id == 'statWidget-calendar') {
					resetCounter();
				}
			}
		}
		//});
	};
	
	const observerOptions = {
		// Off the screen, 1/4 on the screen, and all the way on.
		threshold: [0, 0.25, 1.0]
	};
	
	const observer = new IntersectionObserver(statWatch, observerOptions);
	const statWidgets = document.querySelectorAll('.statWidget');
	//statWidgets.forEach(widget => { 
	for (var widget of statWidgets) {
		observer.observe(widget);
	} 
	//});
}

/*
	END:
	Observe stat widgets entering & leaving the viewport.
*/


/*
	-------------
	INITIALIZE SITE
	-------------
*/
function domready() {
	initCounter();
	init_stat_observer();
}
jQuery(document).ready(domready);


/*
	-------------
	EXPORT what might be wanted in the global scope.
	-------------
*/
//window.resetCounter = resetCounter;
//window.startCounter = startCounter;
